import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'

const AccordionBlock = loadable(() => import('/src/components/PageBuilder/layouts/standard/AccordionBlock'))

const FaqsAccordion = ({ backgroundColour }) => {
    const globalQuery = useStaticQuery(
        graphql`
            query {
                component: allWp {
                    nodes {
                        globalBlocks {
                            faqsAccordion {
                                fieldGroupName
                                faqsAccordion {
                                    addTitleArea
                                    title
                                    description
                                    accordionRepeater {
                                        title
                                        content
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `
    )

    const globalData = globalQuery.component.nodes

    return globalData.map((node, index) => {
        let componentData = node.globalBlocks.faqsAccordion.faqsAccordion

        const titleArea = {
            addTitleArea: componentData.addTitleArea,
            titlePosition: 'centered',
            title: componentData.title,
            description: componentData.description
        }

        return (
            <AccordionBlock
                key={index}
                backgroundColour={backgroundColour}
                titleArea={titleArea}
                accordionRepeater={componentData.accordionRepeater}
                className={'c-faqs-accordion'}
            />
        )
    })
}

FaqsAccordion.propTypes = {
    backgroundColour: PropTypes.oneOf(['light', 'alt', 'dark', 'gradient']).isRequired
}

FaqsAccordion.defaultProps = {
    backgroundColour: 'light'
}

export default FaqsAccordion

export const query = graphql`
    fragment FaqsAccordionForPageBuilder on WpPage_Acfpagebuilder_Layouts_FaqsAccordion {
        fieldGroupName
        backgroundColour
    }
`
